

.PersonHeader {
  background: var(--color-red-overlay-16);

  &__title:before {
    background: url("~@amf/shared/assets/icon-cross-red.svg") no-repeat center;
    background-size: 24px 24px;
  }

  &__card {
    background: var(--color-text-primary);
  }
}

.MatchHeaderAlt__layout:after {
  background: var(--color-red-overlay-16);
}

.MatchHeaderAlt__navigation h1:before {
  background: url("~@amf/shared/assets/icon-cross-red.svg") no-repeat center;
  background-size: 24px 24px;
}

.MatchHeaderAlt {
  background: linear-gradient(100deg, var(--color-text-primary) 50%, rgb(41, 41, 40) 50%);
}

.GroundHeader:after,
.OffenseHeader:after {
  background: var(--color-text-primary);
}
