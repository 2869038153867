
.Menu__item {
  background: var(--color-red-overlay-16);
  color: var(--color-text-primary);

  &--active {
    background: var(--color-red);
    color: var(--color-white);
  }
}
