
.ContactPage {

  &__personPhoto {
  }

  &__infoPanel a,
  &__personInfo a {
    color: var(--color-blue-10);
  }
}
