

h1, h2, h3, h4, h5, h6 {
  color: var(--color-text-primary);
}

h1,
.h1 {

  &:before {
    background: url("~@amf/shared/assets/icon-cross-red-brno.svg") no-repeat center;
    background-size: 24px 24px;
  }
}
