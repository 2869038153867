
@import "~@amf/shared/styles/variables";

$color-red: rgb(186, 44, 27);
$color-text-primary: rgb(26, 26, 25);

:root {
  --color-red: #{$color-red};
  --color-text-primary: #{$color-text-primary};
}

@import "typography";

@import "components/layout";
@import "components/error";
@import "components/navigation";
@import "components/slider";
@import "components/content";
@import "components/footer";
@import "components/news";
@import "components/header";
@import "components/menu";
@import "components/search";

@import "pages/notFoundPage";
@import "pages/newsDetailPage";
@import "pages/contactPage";
