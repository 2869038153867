
.Navigation {

  &__brand {
    width: 107px;
    min-width: 107px;

    @media (max-width: $break-small) {
      width: 79px;
      min-width: 79px;
      height: 40px;
    }
  }

  &--blue &__main {
    background: var(--color-red);
  }

  &__menuItem--selected:after {
    background-image: url("~@amf/shared/assets/icon-cross-white.svg") !important;
  }

  &--white &__menuItem:before {
    background-image: url("~@amf/shared/assets/icon-menu-arrow-blue-jihlava.svg") !important;
  }

  &--white &__submenu {
    background-color: #0B5383;
  }

  &--dropdown &__menuOuter,
  &--dropdown &__signIn {
    background: var(--color-red);
  }

  &--dropdown.Navigation--white .Navigation__submenu {
    background-color: inherit;
  }

  &__submenuItem {
    color: var(--color-text-primary);
  }

  &--white &__menuItem {
    color: var(--color-text-primary);
  }

  &--white &__menuItem--selected {
    color: var(--color-yellow);
  }
}
