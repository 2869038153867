

.AdvancedSearch {


  &__header:after {
    background: var(--color-red-overlay-16);
  }

  h1:before {
    background: url("~@amf/shared/assets/icon-cross-red-brno.svg");
    background-size: 24px 24px;
  }
}
