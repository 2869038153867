
.Slider {
  background: var(--color-black-80);
  margin-bottom: 128px;

  @media (max-width: $break-small) {
    margin-bottom: 40px;
  }

  &__image:after {
    background: linear-gradient(0deg, rgba(0, 0, 0, .64), rgba(0, 0, 0, .72));
  }

  &__hero {

    &Title {

    }

    &Message {

    }
  }
}
